import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectMeta, selectUrl } from "../App/reducer";
import Component from "./layout";

const mapStateToProps = createStructuredSelector({
  meta: selectMeta,
  url: selectUrl,
});

const Layout = connect(mapStateToProps, null)(Component);

export default Layout;
