import * as React from "react";
import { Provider } from "react-redux";

import Layout from "../Layout";
import styles from "./style.scss";

function App({ store, children }) {
  return (
    <Provider store={store}>
      <Layout>
        <div className={styles.container}>{children}</div>
      </Layout>
    </Provider>
  );
}

export default App;
