import * as React from "react";
import { NavLink } from "react-router-dom";

import styles from "./style.scss";

const QUICKLINKS_HTML_ID = "quickLinks";

function QuickLinks({ navigation }) {
  return (
    <ul className={styles.list}>
      {navigation.map((n) => (
        <li key={n.id} className={styles.group}>
          <input
            id={`${QUICKLINKS_HTML_ID}_${n.title.replace(/\s/, "_")}`}
            type="checkbox"
            className={styles.checkbox}
          />
          <label
            htmlFor={`${QUICKLINKS_HTML_ID}_${n.title.replace(/\s/, "_")}`}
            className={styles.label}
          >
            {n.title}
          </label>
          {n.children && Boolean(n.children.length) && (
            <div className={styles.children}>
              <ul
                className={`${styles.columns} ${
                  n.children.length > 6 ? styles.twoColumn : ""
                }`}
              >
                {n.children.map((m) => (
                  <li key={m.id} className={styles.item}>
                    {m.isExternal ? (
                      // eslint-disable-next-line react/jsx-no-target-blank
                      <a
                        href={m.url}
                        className={styles.link}
                        target={m.isNewWindow ? "_blank" : "_self"}
                        rel={m.isNewWindow ? "noreferrer" : ""}
                      >
                        {m.title}
                      </a>
                    ) : (
                      <NavLink
                        to={m.url}
                        className={styles.link}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        {m.title}
                      </NavLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}

export default QuickLinks;
