import reducerRegistry from "../../reducerRegistry";
import { getNewsPage } from "./actions";
import { REDUCER_NAME } from "./constants";
import reducer from "./reducer";

const getNewsSSR = (store, { path, language }) => {
  reducerRegistry.register(REDUCER_NAME, reducer);
  return store.dispatch(getNewsPage({ path, language }));
};

export default getNewsSSR;
