import { format, formatDistanceStrict, isToday, parseISO } from "date-fns";
import { createSelector } from "reselect";

import { REDUCER_NAME, SET_ERROR, SET_NEWS, getKey } from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  news: {},
  nextPage: {},
  pageCount: {},
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
        news: {
          ...state.news,
          [action.key]: action.data.news,
        },
        nextPage: {
          ...state.nextPage,
          [action.key]: action.data.nextPage,
        },
        pageCount: {
          ...state.pageCount,
          [action.key]: action.data.pageCount,
        },
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj)) {
    return formatDistanceStrict(dateObj, new Date(), { addSuffix: true });
  }
  return format(dateObj, "dd-MM-yyyy HH:mm");
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectNews = (state, props) => {
  const news = getState(state).news[getKey(props)];
  if (news) {
    return news.map((m) => ({
      ...m,
      date: toFormattedDate(m.date),
    }));
  }
  return [];
};

export const makeSelectNews = () => createSelector(selectNews, (n) => n);

const selectNextPage = (state, props) =>
  getState(state).nextPage[getKey(props)];

export const makeSelectNextPage = () =>
  createSelector(selectNextPage, (n) => n);

const selectPageCount = (state, props) =>
  getState(state).pageCount[getKey(props)];

export const makeSelectPageCount = () =>
  createSelector(selectPageCount, (n) => n);

export default reducer;
