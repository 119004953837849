import { createSelector } from "reselect";

import { REDUCER_NAME, SET_ERROR, SET_NEWSCATEGORIES } from "./constants";

const initialState = {
  newsCategories: [],
  isReady: false,
  isError: false,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWSCATEGORIES:
      return {
        ...state,
        newsCategories: action.data,
        isReady: true,
        isError: false,
      };
    case SET_ERROR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectNewsCategories = createSelector(getState, (n) =>
  n.newsCategories.map((m) => ({
    ...m,
  }))
);

export default reducer;
