import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import reducerRegistry from "../../reducerRegistry";
import { getLiveIcon } from "./actions";
import { REDUCER_NAME } from "./constants";
import Component from "./liveIcon";
import reducer, {
  selectIsError,
  selectIsReady,
  selectLiveIcon,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  liveIcon: selectLiveIcon,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetLiveIcon: (data) => dispatch(getLiveIcon(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

reducerRegistry.register(REDUCER_NAME, reducer);
