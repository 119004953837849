import { toGridPage, toPage } from "./adapter";
import { getGridPage, getPage } from "./pageService";

const fetchPage = (data) =>
  getPage(data)
    .then((response) => toPage(response))
    .catch((error) => {
      throw error;
    });

const fetchGridPage = (data) =>
  getGridPage(data)
    .then((response) => toGridPage(response))
    .catch((error) => {
      throw error;
    });

export { fetchPage, fetchGridPage };
