import { fetchAdvertisement } from "../../../service/Advertisement";
import { SET_ADVERTISEMENT, SET_ERROR, getKey } from "./constants";

const setAdvertisement = (data, key) => ({
  type: SET_ADVERTISEMENT,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

const getAdvertisement = (data) => (dispatch) =>
  fetchAdvertisement(data)
    .then((response) => {
      dispatch(setAdvertisement(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      dispatch(setError(error, getKey(data)));
    });

// eslint-disable-next-line import/prefer-default-export
export { getAdvertisement };
