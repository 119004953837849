import { createSelector } from "reselect";

import {
  REDUCER_NAME,
  SET_ADVERTISEMENT,
  SET_ERROR,
  getKey,
} from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  advertisement: {},
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADVERTISEMENT:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
        advertisement: {
          ...state.advertisement,
          [action.key]: action.data,
        },
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectAdvertisement = (state, props) =>
  getState(state).advertisement[getKey(props)];

export const makeSelectAdvertisement = () =>
  createSelector(selectAdvertisement, (n) => n);

export default reducer;
