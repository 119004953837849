import { fetchSponsors } from "../../../service/Home";
import { SET_ERROR, SET_HOMESPONSORS } from "./constants";

const setHomeSponsors = (data) => ({
  type: SET_HOMESPONSORS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

const getHomeSponsors = (data) => (dispatch) =>
  fetchSponsors(data)
    .then((response) => {
      dispatch(setHomeSponsors(response));
      return response;
    })
    .catch((error) => {
      dispatch(setError(error));
    });

// eslint-disable-next-line import/prefer-default-export
export { getHomeSponsors };
