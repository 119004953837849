import * as React from "react";

import isDebug from "../../util/debug";
import WARNING_ICON from "./img/warning.svg";
import styles from "./style.scss";

const LABELS = {
  TITLE: "An error has occurred.",
  TEXT: "Please try again later.",
};

function ErrorMessage({ error, errorInfo }) {
  return (
    <div className={styles.errorBoundary}>
      <div className={styles.heading}>
        <img
          src={WARNING_ICON}
          alt=""
          width="32"
          height="32"
          className={styles.image}
        />
        <div>
          <span className={styles.title}>{LABELS.TITLE}</span>
          <span className={styles.text}>{LABELS.TEXT}</span>
        </div>
      </div>
      {isDebug() && error && (
        <details className={styles.details}>
          {error.toString()}
          <br />
          {errorInfo && errorInfo.componentStack}
        </details>
      )}
    </div>
  );
}

export default ErrorMessage;
