export const toImage = (data) => {
  if (data) {
    return {
      src: data.imageUrl,
      alt: data.alternateText,
      width: data.width,
      height: data.height,
      originalSrc: data.imageUrl?.split("?")[0],
    };
  }
  return null;
};

export const toCroppedImage = (data, filter) => {
  if (data && data.length) {
    const sortedData = data
      .filter((n) => !filter || n.title.includes(filter))
      .sort((a, b) => a.width - b.width);
    const defaultImage = sortedData[0];
    const responsiveImageData = {
      src: defaultImage.imageUrl,
      alt: defaultImage.alternateText,
      srcset: `${defaultImage.imageUrl} ${defaultImage.width}w`,
    };
    for (let i = 1; i < sortedData.length; i += 1) {
      responsiveImageData.srcset = `${responsiveImageData.srcset}, ${sortedData[i].imageUrl} ${sortedData[i].width}w`;
    }
    return responsiveImageData;
  }
  return null;
};

/**
 * @description
 * This function takes an array of image objects and sorts them by width. It then filters them based on whether they are mobile or desktop and creates a srcset for each. It returns an object with mobile and desktop image data.
 *
 * @param {array} data - an array of image objects
 * @returns {object} - an object with mobile and desktop image data
 */

export const toCroppedBannerImage = (data) => {
  if (!data || !data.length) {
    return null;
  }

  // Get mobile images
  const mobileSortedData = data
    .filter((n) => n.title.includes("_mobile"))
    .sort((a, b) => a.width - b.width);

  // Get desktop images
  const desktopSortedData = data
    .filter((n) => n.title.includes("_desktop"))
    .sort((a, b) => a.width - b.width);

  // Create desktop image data
  const desktopImageData = {};
  if (desktopSortedData.length) {
    desktopImageData.src = desktopSortedData[0].imageUrl;
    desktopImageData.media = "screen and (min-width: 54em)";
    desktopImageData.srcset = `${desktopSortedData[0].imageUrl} ${desktopSortedData[0].width}w`;
    for (let i = 1; i < desktopSortedData.length; i += 1) {
      desktopImageData.srcset = `${desktopImageData.srcset}, ${desktopSortedData[i].imageUrl} ${desktopSortedData[i].width}w`;
    }
  }

  // Create mobile image data
  const mobileImageData = {};
  if (mobileSortedData.length) {
    mobileImageData.src = mobileSortedData[0].imageUrl;
    mobileImageData.srcset = `${mobileSortedData[0].imageUrl} ${mobileSortedData[0].width}w`;
    for (let i = 1; i < mobileSortedData.length; i += 1) {
      mobileImageData.srcset = `${mobileImageData.srcset}, ${mobileSortedData[i].imageUrl} ${mobileSortedData[i].width}w`;
    }
  }

  const defaultImage = mobileSortedData[0] || desktopSortedData[0];

  return {
    sources: [desktopImageData, mobileImageData],
    src: defaultImage.imageUrl,
    srcset: `${defaultImage.imageUrl} ${defaultImage.width}w`,
  };
};

export const toResponsiveImage = (data) => {
  if (data && data.length) {
    const defaultImage = data[0];
    const responsiveImageData = {
      src: defaultImage.imageUrl,
      alt: defaultImage.alternateText,
      srcset: `${defaultImage.imageUrl} ${defaultImage.width}w`,
    };
    // If there any images left over, append their contents to the srcset
    for (let i = 1; i < data.length; i += 1) {
      responsiveImageData.srcset = `${responsiveImageData.srcset}, ${data[i].imageUrl} ${data[i].width}w`;
    }
    return responsiveImageData;
  }
  return null;
};

export const toVideo = (data) => {
  if (data) {
    return {
      title: data.title,
      src: data.videoUrl,
    };
  }
  return null;
};
