import { fetchLive } from "../../../service/Live";
import { SET_ERROR, SET_LIVE_ICON } from "./constants";

const setLiveIcon = (data, key) => ({
  type: SET_LIVE_ICON,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

const getLiveIcon = (data) => (dispatch) =>
  fetchLive(data)
    .then((response) => {
      dispatch(setLiveIcon(response));
      return response;
    })
    .catch((error) => {
      dispatch(setError(error));
    });

// eslint-disable-next-line import/prefer-default-export
export { getLiveIcon };
