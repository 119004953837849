import { fetchNewsCategories } from "../../../service/News";
import { SET_NEWSCATEGORIES } from "./constants";

const setNewsCategories = (data, key) => ({
  type: SET_NEWSCATEGORIES,
  data,
  key,
});

// eslint-disable-next-line import/prefer-default-export
export const getNewsCategories = (data) => (dispatch) =>
  fetchNewsCategories(data)
    .then((response) => {
      dispatch(setNewsCategories(response));
      return response;
    })
    .catch((error) => {
      throw error;
    });
