import { toPage } from "../Page/adapter";

const toFeaturedPages = (data) => (data ? data.map((n) => toPage(n)) : []);

const toHome = (data) => {
  if (data) {
    return {
      featuredPages: toFeaturedPages(data.featuredPages),
      ...toPage(data),
    };
  }
  return null;
};

const toSponsors = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        url: n.url,
        image: n.logo?.imageUrl,
        tier: n.tier,
        width: n.logo?.width,
        height: n.logo?.height,
      }))
    : null;

// eslint-disable-next-line import/prefer-default-export
export { toHome, toSponsors };
