import { toImage } from "../adapter";

const toAdvertisement = (data) => ({
  type: data.type,
  competitionTypeId: data.competitionTypeId,
  images: toImage(data.image),
  url: data.url,
  key: data.key,
  id: data.id,
});

// eslint-disable-next-line import/prefer-default-export
export { toAdvertisement };
