import * as React from "react";

import HomeSponsors from "../HomeSponsors";
import Navigation from "../Navigation";
import QuickLinks from "../QuickLinks";
import SocialLinks from "../SocialLinks";
import styles from "./style.scss";

const LABELS = {
  COPYRIGHT_TEXT: `Copyright © ${new Date().getFullYear()} MFA.`,
  RIGHTS_RESERVED_TEXT: "All rights reserved.",
};

const QUICKLINKS_NAVIGATION_TYPE = "corp-quickLinks";

function Footer() {
  return (
    <footer className={styles.footer}>
      <HomeSponsors />
      <section className={styles.section}>
        <div className={styles.content}>
          <Navigation type={QUICKLINKS_NAVIGATION_TYPE}>
            {({ navigation }) => <QuickLinks navigation={navigation} />}
          </Navigation>
          <div className={styles.socialLinks}>
            <SocialLinks />
          </div>
          <div className={styles.footerText}>
            <span className={styles.copyright}>{LABELS.COPYRIGHT_TEXT}</span>
            <span>{LABELS.RIGHTS_RESERVED_TEXT}</span>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
