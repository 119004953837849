import * as React from "react";

import Footer from "../Footer";
import Header from "../Header";
import Meta from "../Meta";
import TopAdvertisement from "../TopAdvertisement";
import styles from "./style.scss";

function Layout({ meta, children, url }) {
  return (
    <>
      <Meta
        title={meta.title}
        description={meta.description}
        keywords={meta.keywords}
        image={meta.image}
        url={url}
      />
      <TopAdvertisement uid="corporate-top" />
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </>
  );
}

export default Layout;
