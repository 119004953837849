import {
  toLatestNews,
  toNewsCategories,
  toNewsPage,
  toNextPage,
} from "./adapter";
import {
  getLatestNews,
  getNews,
  getNewsByCategory,
  getNewsCategories,
  getNewsPage,
  queryNews,
} from "./dataService";

const PAGE_SIZE = 4;

const fetchLatestNews = (data) =>
  getLatestNews(data)
    .then((response) => toLatestNews(response))
    .catch((error) => {
      console.error(error);
      throw error;
    });

const fetchNewsByCategory = (data) => {
  if (data && data.category) {
    const { page, pageSize } = data;

    return getNewsByCategory({
      pageSize: pageSize || PAGE_SIZE,
      ...data,
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((response) => ({
              nextPage: toNextPage({
                page: page || 1,
                pageCount: res.headers.get("PageSize"),
              }),
              pageCount: res.headers.get("PageSize"),
              itemCount: res.headers.get("Page"),
              news: toLatestNews(response),
            }))
            .catch((error) => {
              console.error(error);
              throw error;
            });
        }
        throw new Error(`${res.statusText}: ${res.url}`);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  return [];
};

const fetchNews = (data) => {
  if (data && data.category) {
    return fetchNewsByCategory(data);
  }

  const { page, pageSize } = data;
  return getNews({
    pageSize: pageSize || PAGE_SIZE,
    ...data,
  })
    .then((res) => {
      if (res.ok) {
        return res
          .json()
          .then((response) => ({
            nextPage: toNextPage({
              page: page || 1,
              pageCount: res.headers.get("PageSize"),
            }),
            pageCount: res.headers.get("PageSize"),
            itemCount: res.headers.get("Page"),
            news: toLatestNews(response),
          }))
          .catch((error) => {
            console.error(error);
            throw error;
          });
      }
      throw new Error(`${res.statusText}: ${res.url}`);
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const searchNews = (data) => {
  const { page, pageSize } = data;
  return queryNews({
    pageSize: pageSize || PAGE_SIZE,
    ...data,
  })
    .then((res) => {
      if (res.ok) {
        return res
          .json()
          .then((response) => ({
            nextPage: toNextPage({
              page: page || 1,
              pageCount: res.headers.get("PageSize"),
            }),
            pageCount: res.headers.get("PageSize"),
            itemCount: res.headers.get("Page"),
            news: toLatestNews(response),
          }))
          .catch((error) => {
            console.error(error);
            throw error;
          });
      }
      throw new Error(`${res.statusText}: ${res.url}`);
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const fetchNewsCategories = (data) =>
  getNewsCategories(data)
    .then((response) => toNewsCategories(response))
    .catch((error) => {
      console.error(error);
      throw error;
    });

const fetchNewsPage = (data) =>
  getNewsPage(data)
    .then((response) => toNewsPage(response))
    .catch((error) => {
      console.error(error);
      throw error;
    });

export {
  fetchNews,
  fetchLatestNews,
  fetchNewsCategories,
  fetchNewsByCategory,
  fetchNewsPage,
  searchNews,
};
