import * as React from "react";
import { NavLink } from "react-router-dom";

import styles from "./style.scss";

const NAVIGATION_HTML_ID = "header";

function HeaderNavigation({ navigation }) {
  return (
    <ul className={styles.list}>
      {navigation.map((n) => (
        <li key={n.id} className={styles.group}>
          <input
            id={`${NAVIGATION_HTML_ID}_${n.title.replace(/\s/, "_")}`}
            type="checkbox"
            className={styles.checkbox}
          />
          <div
            className={`${styles.title}  ${
              n.children.length ? "" : styles.hideCaret
            }`}
          >
            {Boolean(n.children.length) && (
              <label
                htmlFor={`${NAVIGATION_HTML_ID}_${n.title.replace(/\s/, "_")}`}
                className={styles.label}
              >
                {n.title}
              </label>
            )}
            {!n.children.length && n.isExternal && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={n.url}
                className={styles.link}
                target={n.isNewWindow ? "_blank" : "_self"}
                rel={n.isNewWindow ? "noreferrer" : ""}
              >
                {n.title}
              </a>
            )}
            {!n.children.length && !n.isExternal && (
              <NavLink to={n.url} className={styles.link}>
                {n.title}
              </NavLink>
            )}
          </div>
          {n.children && Boolean(n.children.length) && (
            <div className={styles.children}>
              <ul
                className={`${styles.columns} ${
                  n.children.length > 6 ? styles.twoColumn : ""
                }`}
              >
                {n.children.map((m) => (
                  <li key={m.id}>
                    {m.isExternal ? (
                      // eslint-disable-next-line react/jsx-no-target-blank
                      <a
                        href={m.url}
                        className={styles.leaf}
                        target={m.isNewWindow ? "_blank" : "_self"}
                        rel={m.isNewWindow ? "noreferrer" : ""}
                      >
                        {m.title}
                      </a>
                    ) : (
                      <NavLink to={m.url} className={styles.leaf}>
                        {m.title}
                      </NavLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}

export default HeaderNavigation;
