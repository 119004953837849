export const REDUCER_NAME = "newsArchive";
export const SET_NEWS = `${REDUCER_NAME}/SET_NEWS`;
export const SET_ERROR = `${REDUCER_NAME}/SET_ERROR`;

export const PAGE_SIZE = 18;

export const getKey = (data) =>
  `${data.category || "all"}_${data.tag || "all"}_${data.page || 1}_${
    data.dateFrom || "all"
  }_${data.dateTo || "all"}`;
