import reducerRegistry from "../../reducerRegistry";
import { getSocialLinks } from "./actions";
import { REDUCER_NAME } from "./constants";
import reducer from "./reducer";

function getSocialLinksSSR(store) {
  reducerRegistry.register(REDUCER_NAME, reducer);

  return store.dispatch(getSocialLinks());
}

export default getSocialLinksSSR;
