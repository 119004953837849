import reducerRegistry from "../../reducerRegistry";
import { getLatestNews } from "./actions";
import { PAGE_SIZE, REDUCER_NAME } from "./constants";
import reducer from "./reducer";

const getLatestNewsSSR = (store) => {
  reducerRegistry.register(REDUCER_NAME, reducer);

  return store.dispatch(getLatestNews({ pageSize: PAGE_SIZE }));
};

export default getLatestNewsSSR;
