import * as React from "react";

import usePoll from "../../hooks/poll";
import styles from "./style.scss";

const POLL_INTERVAL = 60000;

const LABELS = {
  TITLE: "Live",
};

function Component({ liveIcon, isReady, isError, onGetLiveIcon }) {
  React.useEffect(() => {
    if (!isReady) {
      onGetLiveIcon();
    }
  }, [isReady, onGetLiveIcon]);

  const handleGetLiveIcon = React.useCallback(
    () => onGetLiveIcon(),
    [onGetLiveIcon]
  );

  usePoll({
    callback: handleGetLiveIcon,
    interval: POLL_INTERVAL,
  });

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  if (!(liveIcon && liveIcon.length)) {
    return null;
  }

  return (
    <a href={MC_URL} className={styles.link} target="_blank" rel="noreferrer">
      {LABELS.TITLE}
    </a>
  );
}

export default Component;
