import * as React from "react";
import { Link } from "react-router-dom";

import HamburgerNavigation from "../HamburgerNavigation";
import Navigation from "../Navigation";
import Search from "../Search";
import SocialLinks from "../SocialLinks";
import TopNavigation from "../TopNavigation";
import logo from "./img/logo.svg";
import styles from "./style.scss";

const TOP_NAVIGATION_TYPE = "corp-top";

const Header = React.memo(() => (
  <>
    <Navigation type={TOP_NAVIGATION_TYPE}>
      {({ navigation }) => <TopNavigation navigation={navigation} />}
    </Navigation>
    <header className={styles.header}>
      <div className={styles.background} />
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Link to="/" className={styles.logo}>
            <img
              src={logo}
              alt={process.env.DESCRIPTION}
              title={process.env.DESCRIPTION}
              className={styles.logoImg}
              width={242}
              height={56}
            />
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <HamburgerNavigation />
          <Search />
          <div className={styles.socialLinks}>
            <SocialLinks />
          </div>
        </div>
      </div>
    </header>
  </>
));

export default Header;
