import { toImage } from "../adapter";
import { toPage } from "../Page/adapter";

const toNewsCategory = (data) =>
  data
    ? {
        id: data.id,
        title: data.title,
        thumbnail: toImage(data.thumbnail),
        url: data.url,
        isFeatured: data.isFeatured,
      }
    : null;

const toLatestNews = (data) =>
  data
    ? data.map((n) => ({
        ...toPage(n),
        tags: n.tags && n.tags.filter((m) => m),
        date: n.date,
        isFeatured: n.isFeatured,
        category: toNewsCategory(n.category),
        video: n.video,
      }))
    : [];

const toNewsCategories = (data) =>
  data ? data.map((n) => toNewsCategory(n)) : [];

const toNextPage = ({ page, pageCount }) => {
  const pageCountAsInt = parseInt(pageCount, 10);
  if (
    pageCountAsInt &&
    !Number.isNaN(pageCountAsInt) &&
    pageCountAsInt > 1 &&
    page < pageCountAsInt
  ) {
    return page + 1;
  }

  return null;
};

export const toNewsPage = (data) =>
  data
    ? {
        ...toPage(data),
        tags: data.tags && data.tags.filter((n) => n),
        summary: data.summary,
        date: data.date,
        isFeatured: data.isFeatured,
        category: toNewsCategory(data.category),
        video: data.video,
      }
    : null;

export { toLatestNews, toNewsCategories, toNextPage };
