import fetch from "node-fetch";

const getNavigation = async ({ type }) => {
  const res = await fetch(`${CMS_URL}/api/navigations/?type=${type}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

// eslint-disable-next-line import/prefer-default-export
export { getNavigation };
