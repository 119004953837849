import reducerRegistry from "../../reducerRegistry";
import { getNews } from "./actions";
import { PAGE_SIZE, REDUCER_NAME } from "./constants";
import reducer from "./reducer";

const getNewsSearchSSR = (store, { path, query }) => {
  reducerRegistry.register(REDUCER_NAME, reducer);

  const { q } = query;

  return store.dispatch(getNews({ query: q, path, pageSize: PAGE_SIZE }));
};

export default getNewsSearchSSR;
