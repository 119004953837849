import { fetchNavigation } from "../../../service/Navigation";
import { SET_ERROR, SET_NAVIGATION, getKey } from "./constants";

const setNavigation = (data, key) => ({
  type: SET_NAVIGATION,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

const getNavigation = (data) => (dispatch) =>
  fetchNavigation(data)
    .then((response) => {
      dispatch(setNavigation(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      dispatch(setError(error, getKey(data)));
    });

// eslint-disable-next-line import/prefer-default-export
export { getNavigation };
