import { fetchNewsPage } from "../../../service/News";
import { makeGetPage } from "../Page/actions";
import { SET_LANGUAGE, SET_NEWS } from "./constants";

const setNews = (data) => ({
  type: SET_NEWS,
  data,
});

const setLanguage = (data) => ({
  type: SET_LANGUAGE,
  data,
});

export const getNews = (data) => (dispatch) =>
  fetchNewsPage(data)
    .then((response) => {
      dispatch(setLanguage({ language: data.language }));
      dispatch(setNews(response));
      return response;
    })
    .catch((error) => {
      throw error;
    });

export const getNewsPage = (data) => (dispatch) =>
  makeGetPage(getNews)(data)(dispatch);
