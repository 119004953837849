import fetch from "node-fetch";

const getSocialLinks = async () => {
  const res = await fetch(`${CMS_URL}/api/socialLinks/`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(res.statusText);
};

// eslint-disable-next-line import/prefer-default-export
export { getSocialLinks };
