import { toEducationCategories, toEducationDetailPage } from "./adapter";
import { getEducationDetailPage, getEducationItems } from "./dataService";

const fetchEducationCategories = (data) =>
  getEducationItems(data)
    .then((response) => toEducationCategories(response))
    .catch((error) => {
      throw error;
    });

const fetchEducationDetailPage = (data) =>
  getEducationDetailPage(data)
    .then((response) => toEducationDetailPage(response))
    .catch((error) => {
      throw error;
    });

// eslint-disable-next-line import/prefer-default-export
export { fetchEducationCategories, fetchEducationDetailPage };
