import { toCroppedImage, toImage } from "../adapter";
import {
  toBannerImage,
  toBanners,
  toCover,
  toDownloads,
  toGallery,
  toMeta,
} from "../Page/adapter";

const toEducationItems = (data) =>
  data && data.length
    ? data.map((n) => ({
        id: n.id,
        title: n.title,
        url: n.url,
        isOpen: n.isOpen,
        image: toImage(n.image),
      }))
    : [];

const toEducationCategories = (data) =>
  data && data.length
    ? data.map((n) => ({
        id: n.id,
        title: n.title,
        items: toEducationItems(n.educationItems),
      }))
    : [];

const toEducationDetailPage = (data) => {
  if (data) {
    return {
      id: data.id,
      name: data.name,
      title: data.title,
      summary: data.summary,
      html: data.html,
      url: data.url,
      subNav: data.subNavItemName,
      thumbnail: toImage(data.thumbnail),
      banners: toBanners(data.banners),
      cover: toCover(data.gallery),
      downloads: toDownloads(data.downloadCategories),
      bannerImage: toBannerImage(data.gallery),
      gallery: toGallery(data.gallery),
      meta: toMeta({
        ...data.meta,
        thumbnail: data.thumbnail ? data.thumbnail.imageUrl : "",
      }),
      category: {
        id: data.category.id,
        title: data.category.title,
        url: data.category.url,
      },
      abbreviation: data.abbreviation,
      overviewHtml: data.overview,
      applyButtonUrl: data.applyButton,
      showInterestUrl: data.showInterestButton,
      moreDetailsHtml: data.moreDetails,
      modeOfAssessment: Object.entries(data.typesOfAssessment).map((n) => ({
        key: n[0].replace("_", " "),
        value: n[1],
      })),
      features: Object.entries(data.features).map((n) => ({
        key: n[0].replace("_", " "),
        value: n[1],
      })),
      isOpen: data.isOpen,
      image: toCroppedImage(data.image),
      duration: data.features?.duration,
      price: data.features?.price,
      status: data.features?.status,
      startDate: data.features?.starting,
    };
  }
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export { toEducationCategories, toEducationDetailPage };
