import * as React from "react";
import { useNavigate } from "react-router";

import Icon from "./img/icon.svg";
import styles from "./styles.scss";

const LABELS = {
  SEARCH: "Search",
};

function Search() {
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const detailsRef = React.useRef(null);

  const handleToggle = () => {
    requestAnimationFrame(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const entries = Object.fromEntries(formData.entries());
    const query = new URLSearchParams(entries);
    navigate(`/search/?${query}`);

    detailsRef.current?.removeAttribute("open");
  };

  return (
    <div className={styles.search}>
      <details ref={detailsRef}>
        <summary onClick={handleToggle} className={styles.summary}>
          <img src={Icon} alt={LABELS.SEARCH} width="20" height="20" />
        </summary>
        <div className={styles.details}>
          <form onSubmit={handleSubmit} className={styles.inner}>
            <input
              ref={inputRef}
              type="text"
              name="q"
              placeholder={LABELS.SEARCH}
              className={styles.input}
            />
            <button
              type="submit"
              className={styles.button}
              title={LABELS.SEARCH}
            >
              {LABELS.SEARCH}
            </button>
          </form>
        </div>
      </details>
    </div>
  );
}

export default Search;
