import { toAdvertisement } from "./adapter";
import { getAdvertisement } from "./dataService";

const fetchAdvertisement = (data) =>
  getAdvertisement(data)
    .then((response) => toAdvertisement(response))
    .catch(() => {
      // do nothing
    });

// eslint-disable-next-line import/prefer-default-export
export { fetchAdvertisement };
