import * as React from "react";

import ErrorMessage from "../ErrorMessage";
import styles from "./style.scss";

function Component({ onGetHomeSponsors, isReady, isError, sponsors }) {
  React.useEffect(() => {
    if (!isReady) {
      onGetHomeSponsors();
    }
  }, [onGetHomeSponsors, isReady]);

  if (isError) {
    return <ErrorMessage />;
  }

  if (!isReady) {
    return null;
  }

  return (
    <section className={styles.section}>
      <ul className={styles.list}>
        {sponsors.map((n) => (
          <li className={styles.listItem} key={n.id}>
            <a href={n.url} target="_blank" rel="noreferrer">
              <img
                src={n.image}
                alt={n.name}
                title={n.name}
                width={n.width}
                height={n.height}
                className={styles.listItemImg}
                loading="lazy"
                crossOrigin="anonymous"
              />
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default Component;
