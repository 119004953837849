import reducerRegistry from "../../reducerRegistry";
import { getLatestNews } from "./actions";
import { PAGE_SIZE, REDUCER_NAME } from "./constants";
import reducer from "./reducer";

const getHomeBannerSSR = (store) => {
  reducerRegistry.register(REDUCER_NAME, reducer);

  return store.dispatch(
    getLatestNews({ isFeatured: true, pageSize: PAGE_SIZE })
  );
};

export default getHomeBannerSSR;
