import { fetchHome } from "../../../service/Home";
import { makeGetPage } from "../Page/actions";
import { SET_HOME } from "./constants";

const setHome = (data) => ({
  type: SET_HOME,
  data,
});

export const getHome = (data) => (dispatch) =>
  fetchHome(data)
    .then((response) => {
      dispatch(setHome(response));
      return response;
    })
    .catch((error) => {
      throw error;
    });

export const getHomePage = (data) => (dispatch) =>
  makeGetPage(getHome)(data)(dispatch);
