import { format, formatDistanceStrict, isToday, parseISO } from "date-fns";
import { createSelector } from "reselect";

import { REDUCER_NAME, SET_LANGUAGE, SET_NEWS } from "./constants";

const initialState = {
  language: "",
  tags: [],
  date: null,
  category: null,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.data.language,
      };
    case SET_NEWS:
      return {
        ...state,
        tags: action.data.tags,
        date: action.data.date,
        category: action.data.category,
        isReady: true,
        isError: false,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj)) {
    return formatDistanceStrict(dateObj, new Date(), { addSuffix: true });
  }
  return format(dateObj, "dd-MM-yyyy HH:mm");
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectLanguage = createSelector(getState, (n) => n.language);

export const selectDate = createSelector(getState, (n) =>
  toFormattedDate(n.date)
);

export const selectCategory = createSelector(getState, (n) => n.category);

export const selectTags = createSelector(getState, (n) => n.tags);

export default reducer;
