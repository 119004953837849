const requestIdleCallback =
  window.requestIdleCallback ||
  ((cb) =>
    setTimeout(() => {
      const start = Date.now();
      cb({
        didTimeout: false,
        timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
      });
    }, 1));

const cancelIdleCallback =
  window.cancelIdleCallback ||
  ((id) => {
    clearTimeout(id);
  });

export { requestIdleCallback, cancelIdleCallback };
