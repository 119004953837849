const toNavigationItems = (data) =>
  data && data.length
    ? data.map((n) => ({
        id: n.id,
        title: n.title,
        url: n.url,
        isNewWindow: n.isNewWindow,
        isExternal: n.isExternal,
        icon: n.icon?.imageUrl,
        children: toNavigationItems(n.children),
      }))
    : [];

const toNavigation = (data) =>
  data && data.children ? toNavigationItems(data.children) : [];

// eslint-disable-next-line import/prefer-default-export
export { toNavigation };
