import { format, formatDistanceStrict, isToday, parseISO } from "date-fns";
import { createSelector } from "reselect";

import { REDUCER_NAME, SET_ERROR, SET_LATESTNEWS } from "./constants";

const initialState = {
  news: [],
  isReady: false,
  isError: false,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LATESTNEWS:
      return {
        ...state,
        news: action.data,
        isReady: true,
        isError: false,
      };
    case SET_ERROR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj)) {
    return formatDistanceStrict(dateObj, new Date(), { addSuffix: true });
  }
  return format(dateObj, "dd-MM-yyyy HH:mm");
};

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectLatestNews = createSelector(getState, (n) =>
  n.news.map((m) => ({
    ...m,
    date: toFormattedDate(m.date),
  }))
);

export default reducer;
