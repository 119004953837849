import * as React from "react";

import styles from "./style.scss";

function Advertisement({
  advertisement,
  isReady,
  isError,
  onGetAdvertisement,
  uid,
  type,
  children,
}) {
  React.useEffect(() => {
    if (!isReady) {
      onGetAdvertisement({ uid, type });
    }
  }, [onGetAdvertisement, isReady, uid, type]);

  const handleClick = React.useCallback(() => {
    if (advertisement && advertisement.url) {
      window.open(advertisement.url, "_blank");
    }
  }, [advertisement]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  if (!advertisement) {
    return null;
  }

  return (
    <div role="complementary" className={styles.advertisement}>
      {children(advertisement, handleClick)}
    </div>
  );
}

export default Advertisement;
