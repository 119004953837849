import { toSocialLinks } from "./adapter";
import { getSocialLinks } from "./dataService";

const fetchSocialLinks = (data) =>
  getSocialLinks(data)
    .then((response) => toSocialLinks(response))
    .catch((error) => {
      console.error(error);
      throw error;
    });

// eslint-disable-next-line import/prefer-default-export
export { fetchSocialLinks };
