import reducerRegistry from "../../reducerRegistry";
import { getNavigation } from "./actions";
import { REDUCER_NAME } from "./constants";
import reducer from "./reducer";

const NAVIGATION_TYPES_SSR = ["corp-header", "corp-top"];

function getNavigationSSR(store) {
  reducerRegistry.register(REDUCER_NAME, reducer);

  return Promise.all(
    NAVIGATION_TYPES_SSR.map((n) => store.dispatch(getNavigation({ type: n })))
  );
}

export default getNavigationSSR;
