import * as React from "react";
import { Link } from "react-router-dom";

import LiveIcon from "../LiveIcon";
import styles from "./style.scss";

function TopNavigation({ navigation }) {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {navigation.map((n) => (
          <li key={n.id} className={styles.item}>
            {n.icon && (
              <img
                src={n.icon}
                alt=""
                width="20"
                height="20"
                className={styles.icon}
              />
            )}
            {n.isExternal ? (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={n.url}
                className={styles.link}
                target={n.isNewWindow ? "_blank" : "_self"}
                rel={n.isNewWindow ? "noreferrer" : ""}
              >
                {n.title}
              </a>
            ) : (
              <Link to={n.url} className={styles.link}>
                {n.title}
              </Link>
            )}
          </li>
        ))}
        <li className={styles.item}>
          <LiveIcon />
        </li>
      </ul>
    </div>
  );
}

export default TopNavigation;
