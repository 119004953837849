import { fetchLatestNews } from "../../../service/News";
import { SET_LATESTNEWS } from "./constants";

const setLatestNews = (data, key) => ({
  type: SET_LATESTNEWS,
  data,
  key,
});

// eslint-disable-next-line import/prefer-default-export
export const getLatestNews = (data) => (dispatch) =>
  fetchLatestNews(data)
    .then((response) => {
      dispatch(setLatestNews(response));
      return response;
    })
    .catch((error) => {
      throw error;
    });
