import fetch from "node-fetch";

const getPage = async ({ path }) => {
  const res = await fetch(`${CMS_URL}/api/pages?url=${path}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

const getGridPage = async ({ path }) => {
  const res = await fetch(`${CMS_URL}/api/pages?url=${path}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

export { getPage, getGridPage };
