import { toHome, toSponsors } from "./adapter";
import { getSponsors } from "./dataService";
import { getPage } from "./pageService";

const fetchHome = (data) =>
  getPage(data)
    .then((response) => toHome(response))
    .catch((error) => {
      console.error(error);
      throw error;
    });

const fetchSponsors = (data) =>
  getSponsors(data)
    .then((response) => toSponsors(response))
    .catch((error) => {
      console.error(error);
      throw error;
    });

export { fetchHome, fetchSponsors };
