import fetch from "node-fetch";

const getEducationItems = async () => {
  const res = await fetch(`${CMS_URL}/api/education`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

const getEducationDetailPage = async ({ path }) => {
  const res = await fetch(`${CMS_URL}/api/education?url=${path}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

// eslint-disable-next-line import/prefer-default-export
export { getEducationItems, getEducationDetailPage };
