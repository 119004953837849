import { createSelector } from "reselect";

import { REDUCER_NAME, SET_ERROR, SET_HOMESPONSORS } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  sponsors: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMESPONSORS:
      return {
        ...state,
        isReady: true,
        isError: false,
        sponsors: action.data,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const selectIsReady = (state) => getState(state).isReady;

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state) => getState(state).isError;

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectHomeSponsors = (state) => getState(state).sponsors;

export const makeSelectHomeSponsors = () =>
  createSelector(selectHomeSponsors, (n) => n);

export default reducer;
