import reducerRegistry from "../../reducerRegistry";
import { REDUCER_NAME } from "../Page/constants";
import reducer from "../Page/reducer";
import { getPage } from "./actions";

const getEducationDetailPageSSR = (store, { path }) => {
  reducerRegistry.register(REDUCER_NAME, reducer);

  return store.dispatch(getPage({ path }));
};

export default getEducationDetailPageSSR;
