import reducerRegistry from "../../reducerRegistry";
import { getNewsCategories } from "./actions";
import { REDUCER_NAME } from "./constants";
import reducer from "./reducer";

const getNewsCategoriesSSR = (store) => {
  reducerRegistry.register(REDUCER_NAME, reducer);
  return store.dispatch(getNewsCategories());
};

export default getNewsCategoriesSSR;
