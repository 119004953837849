import fetch from "node-fetch";

const getAdvertisement = async ({ uid, type }) => {
  const res = await fetch(
    `${CMS_URL}/api/advertisement/?type=${type}&${
      uid ? `uid=${uid}` : ""
    }`.replace(/&$/, "")
  );
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

// eslint-disable-next-line import/prefer-default-export
export { getAdvertisement };
