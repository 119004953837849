import { fetchNews } from "../../../service/News";
import { fetchPage } from "../../../service/Page";
import { makeGetPage } from "../Page/actions";
import { SET_NEWS, getKey } from "./constants";

export const setNews = (data, key) => ({
  type: SET_NEWS,
  data,
  key,
});

export const getNews = (data) => (dispatch) =>
  fetchNews(data)
    .then((response) => {
      dispatch(setNews(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      throw error;
    });

export const getNewsArchive = (data) => (dispatch) =>
  Promise.all([fetchNews(data), fetchPage(data)])
    .then(([news, page]) => {
      dispatch(setNews(news, getKey(data)));
      return page;
    })
    .catch((error) => {
      throw error;
    });

export const getNewsArchivePage = (data) => (dispatch) =>
  makeGetPage(getNewsArchive)(data)(dispatch);
