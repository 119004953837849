import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import reducerRegistry from "../../reducerRegistry";
import { getSocialLinks } from "./actions";
import { REDUCER_NAME } from "./constants";
import reducer, {
  selectIsError,
  selectIsReady,
  selectSocialLinks,
} from "./reducer";
import Component from "./socialLinks";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  socialLinks: selectSocialLinks,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSocialLinks: (data) => dispatch(getSocialLinks(data)),
});

const SocialLinks = compose(connect(mapStateToProps, mapDispatchToProps))(
  Component
);

export default SocialLinks;

reducerRegistry.register(REDUCER_NAME, reducer);
