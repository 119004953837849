import { fetchSocialLinks } from "../../../service/Social";
import { SET_ERROR, SET_SOCIALLINKS } from "./constants";

const setSocialLinks = (data) => ({
  type: SET_SOCIALLINKS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

const getSocialLinks = (data) => (dispatch) =>
  fetchSocialLinks(data)
    .then((response) => {
      dispatch(setSocialLinks(response));
      return response;
    })
    .catch((error) => {
      dispatch(setError(error));
    });

// eslint-disable-next-line import/prefer-default-export
export { getSocialLinks };
