import reducerRegistry from "../../reducerRegistry";
import { getNews } from "./actions";
import { PAGE_SIZE, REDUCER_NAME } from "./constants";
import reducer from "./reducer";

const getNewsArchiveSSR = (store, { path, query, match: { params } }) => {
  reducerRegistry.register(REDUCER_NAME, reducer);

  const { tag, dateFrom, dateTo } = query;
  const { category } = params;

  return store.dispatch(
    getNews({ category, tag, path, pageSize: PAGE_SIZE, dateFrom, dateTo })
  );
};

export default getNewsArchiveSSR;
