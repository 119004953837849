const toSocialLinks = (data) =>
  data && data.length
    ? data.map((n) => ({
        title: n.title,
        url: n.url,
        platform: n.platform,
        isHidden: n.isHidden,
        id: n.id,
      }))
    : [];

// eslint-disable-next-line import/prefer-default-export
export { toSocialLinks };
