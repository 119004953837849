import { createSelector } from "reselect";

import { REDUCER_NAME, SET_HOME } from "./constants";

const initialState = {
  featuredPages: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME:
      return {
        ...state,
        featuredPages: action.data.featuredPages,
        isReady: true,
        isError: false,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectHome = createSelector(getState, (n) => ({
  featuredPages: n.featuredPages,
}));

export default reducer;
