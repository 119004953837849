import fetch from "node-fetch";

const getPage = async () => {
  const res = await fetch(`${CMS_URL}/api/home/getPage`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

// eslint-disable-next-line import/prefer-default-export
export { getPage };
