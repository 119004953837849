import * as React from "react";
import { useLocation } from "react-router-dom";

import HeaderNavigation from "../HeaderNavigation";
import Navigation from "../Navigation";
import SocialLinks from "../SocialLinks";
import styles from "./style.scss";

const HEADER_NAVIGATION_TYPE = "corp-header";
const HAMBURGER_MENU_ID = "main_menu";
const HAMBURGER_MENU_TEXT = "Toggle Menu";

function HamburgerNavigation() {
  const [isToggled, setIsToggled] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setIsToggled(false);
  }, [location]);

  React.useEffect(() => {
    if (isToggled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isToggled]);

  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        id={HAMBURGER_MENU_ID}
        className={styles.hamburger}
        checked={isToggled}
        onChange={(e) => {
          setIsToggled(e.target.checked);
        }}
      />
      <label htmlFor={HAMBURGER_MENU_ID} className={styles.label}>
        {HAMBURGER_MENU_TEXT}
      </label>
      <div className={styles.navigationContainer}>
        <Navigation type={HEADER_NAVIGATION_TYPE}>
          {({ navigation }) => <HeaderNavigation navigation={navigation} />}
        </Navigation>
        <div className={styles.socialLinks}>
          <SocialLinks />
        </div>
      </div>
    </div>
  );
}

export default HamburgerNavigation;
