import { connect } from "react-redux";
import { compose } from "redux";

import reducerRegistry from "../../reducerRegistry";
import { getHomeSponsors } from "./actions";
import { REDUCER_NAME } from "./constants";
import Component from "./homeSponsors";
import reducer, {
  makeSelectHomeSponsors,
  makeSelectIsError,
  makeSelectIsReady,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectHomeSponsors = makeSelectHomeSponsors();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();

  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    sponsors: selectHomeSponsors(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetHomeSponsors: (data) => dispatch(getHomeSponsors(data)),
});

export function getHomeSponsorsSSR(store) {
  return store.dispatch(getHomeSponsors());
}

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);

reducerRegistry.register(REDUCER_NAME, reducer);
