import fetch from "node-fetch";

const getLatestNews = async ({ isFeatured, page, pageSize }) => {
  const res = await fetch(
    `${CMS_URL}/api/corporatenews/getNews?${page ? `page=${page}&` : ""}${
      pageSize ? `pageSize=${pageSize}&` : ""
    }${isFeatured ? `isFeatured=${isFeatured}&` : ""}`.replace(/&$/, "")
  );

  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

const getNews = async ({
  tag,
  isFeatured,
  page,
  pageSize,
  dateFrom,
  dateTo,
}) => {
  if (tag) {
    return fetch(
      `${CMS_URL}/api/corporatenews/searchByTag?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${isFeatured ? `isFeatured=${isFeatured}&` : ""}${
        tag ? `tag=${tag}&` : ""
      }${dateFrom ? `from=${dateFrom}&` : ""}${
        dateTo ? `to=${dateTo}&` : ""
      }`.replace(/&$/, "")
    );
  }

  return fetch(
    `${CMS_URL}/api/corporatenews/getNews?${page ? `page=${page}&` : ""}${
      pageSize ? `pageSize=${pageSize}&` : ""
    }${isFeatured ? `isFeatured=${isFeatured}&` : ""}${
      dateFrom ? `from=${dateFrom}&` : ""
    }${dateTo ? `to=${dateTo}&` : ""}`.replace(/&$/, "")
  );
};

const queryNews = async ({ query, page, pageSize }) =>
  fetch(
    `${CMS_URL}/api/corporatenews/search?${page ? `page=${page}&` : ""}${
      pageSize ? `pageSize=${pageSize}&` : ""
    }${query ? `query=${query}&` : ""}`.replace(/&$/, "")
  );

const getNewsByCategory = async ({
  category,
  categoryId,
  tag,
  page,
  pageSize,
  dateFrom,
  dateTo,
}) =>
  fetch(
    `${CMS_URL}/api/corporatenews/getNewsByCategory?${
      category ? `category=${category.replace(/\/?$/, "/")}&` : ""
    }${categoryId ? `categoryId=${categoryId}&` : ""}${
      tag ? `tag=${tag}&` : ""
    }${page ? `page=${page}&` : ""}${pageSize ? `pageSize=${pageSize}&` : ""}${
      dateFrom ? `from=${dateFrom}&` : ""
    }${dateTo ? `to=${dateTo}&` : ""}`.replace(/&$/, "")
  );

const getNewsCategories = async () => {
  const res = await fetch(`${CMS_URL}/api/corporatenews/getCategories`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

const getNewsPage = async ({ path }) => {
  const res = await fetch(
    `${CMS_URL}/api/corporatenews/getDetailPage?url=${path.replace(
      /\/?$/,
      "/"
    )}`
  );
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

export {
  getNews,
  getLatestNews,
  getNewsByCategory,
  getNewsCategories,
  getNewsPage,
  queryNews,
};
